<template>
  <v-card light>
    <v-container fluid v-if="team.isDeleted">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{team.name}} is no longer registered for {{tournament.name}}</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense v-if="view.admin && !tournament.locked">
        <v-col class="text-right" cols="12" >
          <v-btn color="color3" text @click.stop="adminReregister">undo</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else-if="tournament.locked && !user.vbl">
      <v-row dense>
        <v-col class="text-center" >
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>{{tournament.name}} has been locked for editing</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- ADMIN -->
    <v-container fluid v-else-if="userIdAdmin">
      <v-row dense>
        <v-col>
          <v-card color="grey lighten-4">
            <v-card-title>
              <h3>Withdraw from the tournament?</h3>
            </v-card-title>
            <v-card-text class="py-0">
              <v-checkbox
                color="color3"
                label="Yes"
                v-model="confirm"
              ></v-checkbox>
            </v-card-text>
            <v-card-text v-if="inPool" class="pt-0">
              <v-alert type="warning" class="black--text" :value="true">
                This team is currently in a pool
              </v-alert>
              <v-checkbox
                color="color3"
                label="I understand this will change the current pools"
                v-model="poolConfirm"
              ></v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center">
          <v-btn
            color="color3 color3Text--text"
            class="mr-2"
            :disabled="busy || !valid"
            @click="onUnregisterClick"
            :loading="busy"
          >
            Withdraw the team
          </v-btn>
          <v-btn
            @click="onCancelClick"
            :disabled="!valid"
            :loading="busy"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- PUBLIC IN REFUND WINDOW -->
    <v-container fluid v-else-if="refundsOpen">
      <v-row dense>
        <v-col>
          <v-card color="grey lighten-4">
            <v-card-title class="pb-0">
              <h3 style="width: 100%">
                Need to withdraw from the tournament?
              </h3>
              <div>
                Upon completeion, {{team.name}} will be withdrawn from the tournament and
                a refund will be issued.<br>
                Please provide a brief explination of why the team is withdrawing.
              </div>
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-model="reason"
                color="color3"
                :label="`Why ${team.name} is withdrawing`"
                required
                @blur="$v.reason.$touch()"
                :error-messages="reasonErrors"
              ></v-textarea>
              <v-checkbox
                color="color3"
                label="I understand cannot be undone."
                v-model="confirm"
              ></v-checkbox>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" >
          <v-btn
            color="color3 color3Text--text"
            class="mr-2"
            :disabled="busy || !confirm || !reason"
            @click="onUnregisterClick"
            :loading="busy"
          >
            Withdraw
          </v-btn>
          <v-btn
            @click="onCancelClick"
            :disabled="busy || (!confirm && !reason)"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- PUBLIC OUT OF REFUND WINDOW -->
    <v-container fluid v-else>
      <v-row dense>
        <v-col>
          <v-card color="grey lighten-4">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <h3 style="width: 100%">
                    Need to withdraw from the tournament?
                  </h3>
                </v-col>
                <v-col cols="12">
                  <v-alert type="error" :value="true">
                    Per the refund policy, the cut off for canceling this registration was
                    {{ formatDateTime(currentDivision.dtRefundCutoff) }}.
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  Any cancellations now, need to be approved by the tournament director and any refund is at his/her sole discretion.
                  Please enter your detailed request below and we will notify the tournament director.
                </v-col>
                <change-request
                  ref="changeRequest"
                  :teamId="team.id"
                  :busy="busy"
                  type="Cancellation"
                  @valid-change="onChangeRequestValidChange"
                ></change-request>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-center" >
          <v-btn
            color="color3 color3Text--text"
            @click="onSubmitRequest"
            :loading="busy"
            :disabled="busy || !validRequest"
          >Submit request</v-btn>
          <v-btn
            @click="reset()"
            :disabled="busy || !validRequest"
          >Cancel</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import ChangeRequestVue from './ChangeRequest.vue'
import * as mutations from '../../store/MutationTypes'
import { mapGetters } from 'vuex'

export default {
  props: ['tournament', 'team'],
  data () {
    return {
      confirm: false,
      busy: false,
      reason: null,
      validRequest: false,
      poolConfirm: false
    }
  },
  mixins: [validationMixin],
  validations () {
    return {
      reason: this.userIdAdmin ? {} : { required }
    }
  },
  computed: {
    ...mapGetters(['view', 'getTeamToken']),
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    valid () {
      return this.confirm && (!this.inPool || this.poolConfirm)
    },
    inPool () {
      return !!this.tournament.poolTeams.filter(f => f.teamId === this.team.id).length
    },
    userIdAdmin () {
      return this.$store.getters.user && !this.$store.getters.publicView && this.$store.getters.user.isTournamentAdmin(this.tournament)
    },
    reasonErrors () {
      const errors = []
      if (!this.$v.reason.$dirty) return errors
      !this.$v.reason.required && errors.push('Required')
      return errors
    },
    currentDivision () {
      return this.tournament.divisions.find((d) => {
        return d.teams.find((t) => {
          return t.id === this.team.id
        })
      })
    },
    divisions () {
      return this.tournament.divisions.filter((d) => {
        return d.id !== this.currentDivision.id
      })
    },
    refundsOpen () {
      return this.currentDivision.refundsOpen
    },
    order () {
      return this.$store.getters.order
    },
    credentials () {
      return {
        orderId: this.order ? this.order.id : null,
        email: this.order ? this.order.emailReceiptTo : null
      }
    },
    withdrawAndRefundDto () {
      return {
        teamId: this.team.id,
        reason: this.reason,
        credentials: this.credentials
      }
    }
  },
  methods: {
    onCancelClick () {
      this.confirm = false
      this.reason = null
      this.$v.$reset()
    },
    adminWithdraw () {
      this.$VBL.team.unregister(this.team.id)
        .then((response) => {
          this.team.notes = response.data
          this.team.isDeleted = true
          this.$emit('team-deleted')
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    adminReregister () {
      this.busy = true
      this.$VBL.team.reregister(this.team.id)
        .then((response) => {
          this.team.notes = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    userWithdraw () {
      this.$VBL.team.withdrawAndRefund(this.withdrawAndRefundDto, this.teamToken)
        .then((response) => {
          this.team.isDeleted = true
          this.$emit('team-withdrawand-refunded')
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    onUnregisterClick () {
      this.busy = true
      if (this.userIdAdmin) {
        this.adminWithdraw()
      } else {
        this.userWithdraw()
      }
    },
    removeFromDivision () {
      const i = this.currentDivision.teams.findIndex((t) => t.id === this.team.id)
      this.currentDivision.teams.splice(i, 1)
    },
    reset () {
      this.$v.$reset()
      this.confirm = false
      this.reason = null
      if (this.$refs.changeRequest) this.$refs.changeRequest.reset()
    },
    formatDateTime (date) {
      return moment.utc(date).format('LT [on] dddd, MMM Do')
    },
    onSubmitRequest () {
      this.busy = true
      this.$refs.changeRequest.submitRequest()
        .then(response => {
          this.$emit('request-submitted')
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.busy = false
        })
    },
    onChangeRequestValidChange (val) {
      this.validRequest = val
    }
  },
  watch: {
    busy: function (val) {
      this.$emit('busy-change', val)
      const mutation = val ? mutations.LOADING : mutations.LOADING_COMPLETE
      this.$store.commit(mutation, `UNREGISTER_${this.team.id}`)
    },
    confirm: function (v) {
      this.$v.reason.$touch()
    }
  },
  components: {
    'change-request': ChangeRequestVue
  }
}
</script>

<style>

</style>
