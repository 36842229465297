<template>
  <v-textarea
    :label="`${type} Request`"
    v-model="reason"
    :disabled="busy"
    required
    @blur="$v.reason.$touch()"
    :error-messages="reasonErrors"
    color="color3"
  ></v-textarea>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['busy', 'teamId', 'type'],
  data () {
    return {
      confirm: false,
      reason: null
    }
  },
  mixins: [validationMixin],
  validations () {
    return {
      reason: this.userIdAdmin ? {} : { required }
    }
  },
  computed: {
    reasonErrors () {
      const errors = []
      if (!this.$v.reason.$dirty) return errors
      !this.$v.reason.required && errors.push('Required')
      return errors
    },
    requestDto () {
      return {
        request: {
          teamId: this.teamId,
          request: this.reason,
          type: this.type
        },
        credentials: this.credentials
      }
    },
    order () {
      return this.$store.getters.order
    },
    credentials () {
      return {
        orderId: this.order ? this.order.id : null,
        email: this.order ? this.order.emailReceiptTo : null
      }
    },
    valid () {
      return !!this.reason
    }
  },
  methods: {
    reset () {
      this.$v.$reset()
      this.reason = null
    },
    submitRequest () {
      return this.$VBL.team.putChangeRequest(this.requestDto, this.$route.query.token)
    }
  },
  watch: {
    valid: function (val) {
      this.$emit('valid-change', val)
    }
  }
}
</script>

<style>

</style>
